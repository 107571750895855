import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql} from 'gatsby'
import twitterFeatureImg from '../../assets/images/features/twitter-features.webp'
import twitterSetupMImg from '../../assets/images/features/twitter-setup-m-steps.webp'


const Twitter = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Twitter | BlueMail App' 
                postDescription='Share emails through various social networks & messaging apps, receive emails from people who wish to engage with you instantly.'
                postImage='/img/OG/og_image-twitter-futures.png'
                postURL='features-functions/twitter'
                postSEO
            />
            <Navbar />
            <div className='twitter-features'>
                <div className='container-fluid pt-200 pb-50'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 text-center m-auto'>
                            <h1>Save <span>Tweets</span> to your email</h1>
                            <p>Send yourself any Twitter thread. It's 100% free</p>
                        </div>
                    </div>               
                </div>
                <div className='container-fluid twitter-setup'>
                    <div className='container'>                        
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-xs-12 setup-content'>
                                <h2><span>Quick</span> Setup</h2>
                                <ul>
                                    <li>Follow <a href='https://twitter.com/bluemail'>@BlueMail</a></li>
                                    <li>Send a DM to @BlueMail in this format: <br></br> /register user@example.com</li>
                                    <li>Check your email account for an activation link (may take a few mins)</li>
                                    <li>Post on a twitter thread @bluemail save</li>
                                </ul>
                            </div>
                           
                        </div>
                    </div>
                    <div className='col-lg-12 col-md-12 col-xs-12 setup-m'>
                        <img src={twitterSetupMImg} alt= 'twitter feature'/>
                    </div>
                </div>
                <div className='container-fluid pb-100 twitter-cloud'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12 col-xs-12 pt-100 pr-0 pl-0'>
                            <img src={twitterFeatureImg} alt= 'twitter feature'/>
                        </div>
                    </div>                
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default Twitter

export const query = graphql`
query TwitterFeaturePageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`